import * as React from "react";
import { isEmpty, map } from "lodash-es";
import { AccountWidget } from "../common/typings/accounts.typings";
import { Select } from "@sgbs-ui/core";
import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { CRMClientScope } from "../common/sgConnectScopes";
import { getClientsByIds, searchAccounts } from "../api/accounts/accounts.api";
import { mapToAccountWidget, mapToListAccountWidget } from "../common/mappers/account.mapper";
import { renderAccount } from "../common/components/renderAccount";
import { InvalidFeedback } from "../common/components/InvalidFeedback/InvalidFeedback";
import { AccountDisplayOption, CommonPickerProps } from "../common/typings";
import { useMultiSelectPicker } from "../common/hooks/useMultiSelectPicker";
import { useGenerateId } from "../common/hooks/useGenerateId";
import { ClientDto } from "../api/accounts/accounts.api.typings";
import { useFetchGeneric } from "../common/hooks/useFetchGeneric";
import { useFetchSuggestions } from "../common/hooks/useFetchSuggestions";
import { useFetchHistory } from "../common/hooks/useHistory";

const ACCOUNT_SEARCH_FIELDS = ["name", "rctId", "bdrLegalId"];
const PICKER_NAME = "ic-account-multi-picker";

export interface Props extends CommonPickerProps<AccountWidget[]> {
  selectedIds?: string[];
  readonlyIds?: string[];
  withFunds?: boolean;
  levels?: string[];
  displayOption?: AccountDisplayOption;
  legalOnly?: boolean;
  useHistory?: boolean;
  suggestionIds?: string[];
  accountsIds?: string[];
  onClearSuggestions?: (items: AccountWidget[]) => void;
  onRemoveSuggestion?: (item: AccountWidget) => void;
}
const AccountsMultiSelectPicker: React.FC<Props> = ({
  selectedIds,
  readonlyIds,
  maxResultCount,
  emitMode,
  withFunds = false,
  useHistory = false,
  displayOption = "none",
  legalOnly,
  levels,
  suggestionIds,
  accountsIds,
  onReady,
  onChange,
  onClearSuggestions,
  onRemoveSuggestion,
  ...props
}) => {
  const pickerId = useGenerateId(props.id);
  const fetch = useSgConnectFetch(CRMClientScope).fetch as FetchFn;

  const fetchByIds = React.useCallback(
    (ids: string[]): Promise<AccountWidget[]> =>
      getClientsByIds(fetch, ids, { levels, withFunds, displayOption, legalOnly })
        .then(clients => map(clients, client => mapToAccountWidget(client, displayOption)))
        .catch(() => []),
    [fetch, levels, withFunds, displayOption, legalOnly]
  );

  const fetchAccounts = React.useCallback(
    (term: string): Promise<AccountWidget[]> => {
      return searchAccounts(fetch, term, {
        pageSize: maxResultCount,
        withFunds,
        levels,
        displayOption,
        legalOnly,
      }).then(accounts => mapToListAccountWidget(accounts, displayOption));
    },
    [fetch, levels, maxResultCount, withFunds, displayOption, legalOnly]
  );

  const fetchAccountsByIds = React.useCallback((accountsIdsFromHistory? : string[]): Promise<AccountWidget[]> => {
    return getClientsByIds(fetch, accountsIds ?? accountsIdsFromHistory ?? []).then((accountsResults: ClientDto[]) => {
      return mapToListAccountWidget(accountsResults, displayOption);
    });
  }, [fetch, accountsIds, displayOption]);

  const { suggestions } = useFetchSuggestions<AccountWidget>(
    () =>
      getClientsByIds(fetch, suggestionIds ?? []).then(suggestionAccountsDto =>
        mapToListAccountWidget(suggestionAccountsDto, displayOption, true)
      ),
    suggestionIds
  );

  const [selectedAccounts, readOnlyContacts, onSelect] = useMultiSelectPicker(
    fetchByIds,
    emitMode,
    onChange,
    onReady,
    selectedIds,
    readonlyIds
  );

  const [accounts, isLoading, hasError, errorMessage, onTermChange, term] = useFetchGeneric<AccountWidget>(
    fetchAccounts,
    fetchAccountsByIds,
    ACCOUNT_SEARCH_FIELDS,
    accountsIds
  );

  const [history, setHistory, removeItemFromHistory, clearHistory] = useFetchHistory(
    fetchAccountsByIds,
    PICKER_NAME,
    useHistory
  );

  return (
    <>
      <Select.AsyncSelect<AccountWidget>
        id={pickerId}
        items={isEmpty(accounts) ? (!term ? suggestions.concat(history) : []) : accounts}
        onClearSuggestions={(items: AccountWidget[]) => {
          clearHistory();
          onClearSuggestions?.(items);
        }}
        onRemoveSuggestion={(item: AccountWidget) => {
          removeItemFromHistory([item.id]);
          onRemoveSuggestion?.(item);
        }}
        isSuggestionField="isSuggestion"
        selectedItems={selectedAccounts}
        readOnlyItems={readOnlyContacts}
        idField="id"
        labelField="fullCommercialName"
        iconName="business"
        placeholder={props.placeholder}
        errorMessage={errorMessage}
        inError={hasError || props.inError || !!errorMessage}
        onTermChange={onTermChange}
        onChange={items => {
          onSelect(items);
          setHistory(items?.map(item => item.id));
          onTermChange("");
        }}
        isLoading={isLoading}
        keepOrder={false}
        size={props.size}
        isOutline={props.outline}
        disabled={props.disabled}
        eraseSearchTermOnClick={true}
        noResultMessage={"No results found. Try another account."}
      >
        {renderAccount}
      </Select.AsyncSelect>
      {props.inError && <InvalidFeedback errorMessage={props.errorMessage} />}
    </>
  );
};

export default AccountsMultiSelectPicker;
